import { MutationHookOptions, gql } from '@apollo/client';
import { useModifiedMutation } from '../../../../../apollo/hooks/useModifiedMutation';

const MUTATION = gql`
  mutation updateOrder($id: ID!, $input: UpdateOrderInput!) {
    updateOrder(id: $id, input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
  readonly input: {
    readonly supplierId?: string;
    readonly supplierContactId?: string;
    readonly supplierLocationId?: string;
    readonly pickup?: boolean;
    readonly pickupDate?: Date | null;
    readonly lineItems: readonly {
      readonly productId: string;
      readonly quantity: number;
    }[];
  };
};

export type Response = {
  readonly updateOrder: {
    readonly id: string;
  };
};

export const useUpdateOrderMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useModifiedMutation<Response, Variables>(MUTATION, options);
