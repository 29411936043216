import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import Box from '../../../../components/content/Box';
import Container from '../../../../components/content/Container';
import Divider from '../../../../components/content/Divider';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import SplitLayout from '../../../../components/content/SplitLayout';
import TextLink from '../../../../components/content/TextLink';
import Button from '../../../../components/controls/Button';
import ButtonLink from '../../../../components/controls/ButtonLink';
import CheckboxField from '../../../../components/controls/CheckboxField';
import DateField from '../../../../components/controls/DateField';
import DropdownField from '../../../../components/controls/DropdownField';
import Notice from '../../../../components/layout/Notice';
import ToolbarPage from '../../../../components/layout/ToolbarPage';
import Heading from '../../../../components/layout/ToolbarPage/components/Heading';
import ToolbarItem from '../../../../components/layout/ToolbarPage/components/Toolbar/components/ToolbarItem';
import { useGetSuppliers } from '../../../../hooks/useGetSuppliers';
import InventoryToolbar from '../InventoryToolbar';
import CombinableOrders from './components/CombinableOrders';
import OrderLineItems from './components/OrderLineItems';
import OrderMetadata from './components/OrderMetadata';
import Returns from './components/Returns';
import SendToSupplierModal from './components/SendToSupplierModal';
import { useSetOrderStatusMutation } from './mutations/setOrderStatusMutation';
import { useUpdateOrderMutation } from './mutations/updateOrderMutation';
import { useGetOrderQuery } from './queries/getOrderQuery';

type Params = {
  readonly id: string;
};

type Props = {};

export function OrderView({}: Props) {
  const { id } = useParams<Params>();

  const suppliers = useGetSuppliers();

  const [supplierId, setSupplierId] = useState<string>();
  const [supplierContactId, setSupplierContactId] = useState<string>();
  const [supplierLocationId, setSupplierLocationId] = useState<string>();
  const [pickup, setPickup] = useState(false);
  const [pickupDate, setPickupDate] = useState<Date | null>(null);
  const [showSendToSupplierModal, setShowSendToSupplierModal] = useState(false);

  const { data, refetch } = useGetOrderQuery({
    variables: { id: id! },
  });

  const [updateOrder, { loading }] = useUpdateOrderMutation({
    onCompleted: () => refetch(),
  });

  const [setStatus, { loading: setStatusLoading }] = useSetOrderStatusMutation({
    onCompleted: () => refetch(),
  });

  const status = useMemo(
    () => data?.order.status.handle,
    [data?.order.status.handle],
  );

  const supplierContacts = useMemo(() => {
    return (
      suppliers.find((supplier) => supplier.id === supplierId)?.contacts ?? []
    );
  }, [suppliers, supplierId]);

  const supplierLocations = useMemo(() => {
    return (
      suppliers.find((supplier) => supplier.id === supplierId)?.locations ?? []
    );
  }, [suppliers, supplierId]);

  const actions = useMemo(() => {
    if (!data) return null;

    if (data.order.parent !== null) {
      // Lifecycle managed by parent / combined order.
      return null;
    }

    if (status === 'DRAFT') {
      return (
        <Button
          primary
          text="Mark Ready"
          loading={setStatusLoading}
          onClick={() =>
            setStatus({
              variables: {
                id: data.order.id,
                status: 'READY',
              },
            })
          }
        />
      );
    }

    if (status === 'READY') {
      return (
        <Button
          text="Send to Supplier"
          primary
          disabled={!data?.order.supplierContact}
          onClick={() => setShowSendToSupplierModal(true)}
        />
      );
    }
  }, [status, data]);

  const canEdit = useMemo(() => {
    if (data?.order.parent) {
      // Can't edit supplier etc once order has been combined.
      return false;
    }

    return status === 'DRAFT' || status === 'READY';
  }, [status, data]);

  useEffect(() => {
    if (data?.order) {
      setSupplierId(data.order.supplier?.id);
      setSupplierContactId(data.order.supplierContact?.id);
      setSupplierLocationId(data.order.supplierLocation?.id);
      setPickup(data.order.pickup);
      setPickupDate(
        data.order.pickupDate ? new Date(data.order.pickupDate) : null,
      );
    }
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Order #{data.order.orderNumber} | Doug CRM</title>
      </Helmet>
      <ToolbarPage
        requiredCapabilities={['canViewOrders']}
        toolbar={
          <InventoryToolbar>
            <ToolbarItem text={`Order #${data.order.orderNumber}`} />
          </InventoryToolbar>
        }
      >
        <Container>
          <Heading
            text={`Order #${data.order.orderNumber}`}
            actions={actions}
          />

          <SplitLayout
            sidebar={
              <OrderMetadata order={data.order} onUpdate={() => refetch()} />
            }
          >
            {data.order.parent && (
              <Notice
                className="mb-5"
                actions={
                  <ButtonLink
                    to={`/inventory/combined-orders/${data.order.parent.id}`}
                    size="sm"
                    text="View Combined"
                  />
                }
              >
                <p>
                  This order has been combined into a parent order. Purchase
                  orders and lifecycle (status) should be managed there.
                </p>
              </Notice>
            )}
            <Box>
              <ExpandableSection title="Details">
                <div className="grid grid-cols-3 gap-5 mb-5">
                  <DropdownField
                    disabled={!canEdit}
                    label="Supplier"
                    placeholder="Undecided..."
                    options={suppliers.map((supplier) => ({
                      text: supplier.name,
                      value: supplier.id,
                    }))}
                    value={supplierId}
                    onChange={(value) => {
                      setSupplierId(value);

                      // Reset supplier contact and location when supplier changes.
                      setSupplierContactId(undefined);
                      setSupplierLocationId(undefined);
                    }}
                  />
                  {supplierContacts.length > 0 && (
                    <DropdownField
                      disabled={!canEdit}
                      label="Contact"
                      placeholder="Undecided..."
                      options={supplierContacts.map((contact) => ({
                        text: `${contact.name} <${contact.email}>`,
                        value: contact.id,
                      }))}
                      value={supplierContactId}
                      onChange={setSupplierContactId}
                    />
                  )}
                  {supplierLocations.length > 0 && (
                    <DropdownField
                      disabled={!canEdit}
                      label="Location"
                      placeholder="Undecided..."
                      options={supplierLocations.map((location) => ({
                        text: location.name,
                        value: location.id,
                      }))}
                      value={supplierLocationId}
                      onChange={setSupplierLocationId}
                    />
                  )}
                </div>
                {!data.order.parent ? (
                  <div className="grid grid-cols-1 gap-5 mb-5">
                    <CheckboxField
                      disabled={!canEdit}
                      checked={pickup}
                      onChange={setPickup}
                    >
                      Pickup
                    </CheckboxField>
                    {pickup && (
                      <DateField
                        disabled={!canEdit}
                        time
                        label="Pickup Date"
                        value={pickupDate}
                        onChange={setPickupDate}
                      />
                    )}
                  </div>
                ) : (
                  <p>
                    Pickup/delivery managed by{' '}
                    <TextLink
                      to={`/inventory/combined-orders/${data.order.parent.id}`}
                    >
                      parent combined order
                    </TextLink>
                    .
                  </p>
                )}

                {canEdit && (
                  <Button
                    size="sm"
                    text="Update"
                    loading={loading}
                    onClick={() =>
                      updateOrder({
                        variables: {
                          id: data.order.id,
                          input: {
                            supplierId,
                            supplierContactId,
                            supplierLocationId,
                            pickup,
                            pickupDate,
                            lineItems: data.order.lineItems.map((line) => ({
                              productId: line.product.id,
                              quantity: line.quantity,
                            })),
                          },
                        },
                      })
                    }
                  />
                )}
              </ExpandableSection>
              <Divider />
              <ExpandableSection
                title="Items"
                counter={data.order.lineItemsCount}
              >
                <OrderLineItems
                  lineItems={data.order.lineItems}
                  orderStatus={data.order.status.handle}
                  onUpdate={() => refetch()}
                />
              </ExpandableSection>

              {!data.order.parent && (
                <>
                  <Divider />
                  <ExpandableSection
                    title="Combinable"
                    counter={data.order.combinableOrders.length}
                  >
                    <CombinableOrders
                      orderId={data.order.id}
                      orders={data.order.combinableOrders}
                    />
                  </ExpandableSection>
                </>
              )}

              <Divider />

              <Returns
                orderId={data.order.id}
                canReturn={['SENT', 'ARCHIVED'].includes(
                  data.order.status.handle,
                )}
                lineItems={data.order.lineItems}
                returns={data.order.returns}
                onUpdate={() => refetch()}
              />
            </Box>
          </SplitLayout>
        </Container>
      </ToolbarPage>

      {showSendToSupplierModal && (
        <SendToSupplierModal
          order={data.order}
          onClose={() => setShowSendToSupplierModal(false)}
          onSend={async () => {
            await refetch();
            setShowSendToSupplierModal(false);
          }}
        />
      )}
    </>
  );
}
