import CheckboxField from './CheckboxField';

type Props = {
  readonly label?: string;
  readonly value: readonly string[];
  readonly onChange: (value: readonly string[]) => void;
  readonly options: readonly {
    readonly text: string;
    readonly value: string;
  }[];
};

const MultiField = ({ label, value, onChange, options }: Props) => {
  return (
    <div>
      {label && (
        <label className="text-gray-600 text-sm mb-1 font-medium block">
          {label}
        </label>
      )}
      <div className="space-y-1">
        {options.map((option, index) => (
          <div className="flex items-center" key={index}>
            <div className="flex-shrink-0">
              <CheckboxField
                checked={value.includes(option.value)}
                onChange={(checked) =>
                  onChange(
                    checked
                      ? value.concat(option.value)
                      : value.filter((item) => item !== option.value),
                  )
                }
              />
            </div>
            <div className="flex-grow">{option.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiField;
