import { useState } from 'react';
import Button from '../../../../../../components/controls/Button';
import TextField from '../../../../../../components/controls/TextField';
import Modal from '../../../../../../components/overlay/Modal';
import { Response } from '../../queries/getOrderQuery';
import { useSendToSupplierMutation } from './mutations/sendToSupplierMutation';

type Props = {
  readonly order: Response['order'];
  readonly onClose: () => void;
  readonly onSend: () => void;
};

export function SendToSupplierModal({ order, onClose, onSend }: Props) {
  const [message, setMessage] = useState('');

  const [sendToSupplier, { loading }] = useSendToSupplierMutation({
    variables: {
      id: order.id,
      input: {
        message,
      },
    },
    onCompleted: () => onSend(),
  });

  return (
    <Modal heading="Send to Supplier" onClose={onClose}>
      <div className="bg-gray-100 rounded-md p-3 mb-5">
        <div className="font-bold">{order.supplier?.name}</div>
        <div>
          {order.supplierContact?.name} &lt;{order.supplierContact?.email}&gt;
        </div>
      </div>
      <div className="grid gap-5">
        <TextField
          label="Message"
          placeholder="Hi <supplier>, please find attached this purchase order..."
          type="box"
          rows={5}
          value={message}
          onChange={setMessage}
        />
        <div>
          <Button
            text="Send"
            primary
            disabled={!order.supplierContact}
            loading={loading}
            onClick={() => sendToSupplier()}
          />
        </div>
      </div>
    </Modal>
  );
}
