import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { OrderStatusHandle } from '../../../../../globals';

const QUERY = gql`
  query getOrder($id: ID!) {
    order(id: $id) {
      id
      orderNumber
      created
      sentToSupplierAt
      documentSecret
      pickup
      pickupDate
      supplier {
        id
        name
      }
      supplierContact {
        id
        name
        email
      }
      supplierLocation {
        id
        name
      }
      status {
        id
        name
        handle
      }
      lead {
        id
        customer {
          fullName
          email
        }
        installerContact {
          id
          name
          installer {
            id
            name
            delivers
          }
        }
      }
      lineItemsCount
      lineItems {
        id
        quantity
        used
        received
        cost
        product {
          id
          sku
          name
          packSize
          category {
            id
            name
          }
        }
      }
      combinableOrders {
        id
        orderNumber
        status {
          handle
          name
        }
        lead {
          id
          customer {
            fullName
            email
          }
        }
      }
      parent {
        id
      }
      returns {
        id
        created
        returnNumber
        status {
          name
          handle
        }
        lineItems {
          id
          quantity
          product {
            id
            name
            sku
          }
        }
      }
    }
  }
`;

export type Variables = {
  readonly id: string;
};

export type Response = {
  readonly order: {
    readonly id: string;
    readonly orderNumber: string;
    readonly created: string;
    readonly sentToSupplierAt: string | null;
    readonly documentSecret: string;
    readonly pickup: boolean;
    readonly pickupDate: string | null;
    readonly supplier: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly supplierContact: {
      readonly id: string;
      readonly name: string;
      readonly email: string;
    } | null;
    readonly supplierLocation: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly status: {
      readonly id: string;
      readonly name: string;
      readonly handle: 'DRAFT' | 'READY' | 'SENT' | 'ARCHIVED';
    };
    readonly lead: {
      readonly id: string;
      readonly customer: {
        readonly fullName: string;
        readonly email: string;
      };
      readonly installerContact: {
        readonly id: string;
        readonly name: string;
        readonly installer: {
          readonly id: string;
          readonly name: string;
          readonly delivers: boolean;
        };
      } | null;
    };
    readonly lineItemsCount: number;
    readonly lineItems: readonly {
      readonly id: string;
      readonly quantity: number;
      readonly used: number;
      readonly received: number;
      readonly cost: number | null;
      readonly product: {
        readonly id: string;
        readonly sku: string;
        readonly name: string;
        readonly packSize: number;
        readonly category: {
          readonly id: string;
          readonly name: string;
        } | null;
      };
    }[];
    readonly combinableOrders: readonly {
      readonly id: string;
      readonly orderNumber: string;
      readonly status: {
        readonly handle: OrderStatusHandle;
        readonly name: string;
      };
      readonly lead: {
        readonly id: string;
        readonly customer: {
          readonly fullName: string;
          readonly email: string;
        };
      };
    }[];
    readonly parent: {
      readonly id: string;
    } | null;
    readonly returns: {
      readonly id: string;
      readonly created: string;
      readonly returnNumber: string;
      readonly status: {
        readonly name: string;
        readonly handle: 'PENDING' | 'RETURNED' | 'CLOSED';
      };
      readonly lineItems: readonly {
        readonly id: string;
        readonly quantity: number;
        readonly product: {
          readonly id: string;
          readonly name: string;
          readonly sku: string;
        };
      }[];
    }[];
  };
};

export const useGetOrderQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
